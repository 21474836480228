<template>
  <div class="welcome-screen">
    <h1 data-cy="chat-welcome-title" class="title center">
      Herzlich willkommen!
    </h1>
    <Icon
      name="logo"
      :height="$brand.isFonic() ? '130' : '79'"
      :width="$brand.isFonic() ? undefined : '137'"
    />
    <p class="paragraph welcome-screen__text">
      Das FONIC Team freut sich auf einen persönlichen Chat mit Ihnen.
    </p>
    <Textfield
      ref="chatName"
      v-model="name"
      label="Ihr Name"
      data-cy="chat-welcome-name-field"
      :rules="[rules.required]"
      class="welcome-screen__input"
    />
    <Checkbox
      ref="dataPrivacyCheckbox"
      v-model="checked"
      :rules="[rules.required]"
      align-box-start
      data-cy="chat-welcome-checkbox"
    >
      <template #label>
        <span>Ich akzeptiere die </span>
        <a href="/datenschutz" target="_blank" @click.stop
          >Datenschutzerklärung</a
        >
      </template>
    </Checkbox>
    <ButtonComponent
      label="Chat starten"
      data-cy="chat-welcome-start-button"
      :clickhandler="startChat"
      >Chat starten</ButtonComponent
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ruleRequired } from 'src/lib/rules'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Checkbox from 'src/components/common/input/checkbox.vue'
import Icon from '../../icon/icon.vue'
import Textfield from 'src/components/common/input/textfield.vue'
import validate from 'src/mixins/validate'

export default {
  name: 'ChatWelcomeScreen',
  components: {
    Textfield,
    Checkbox,
    ButtonComponent,
    Icon
  },
  mixins: [validate],
  inject: ['$http'],
  data() {
    return {
      name: '',
      checked: false,
      rules: {
        required: ruleRequired
      }
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authentication.loggedIn || false,
      personal: state => state.personal || {},
      hostName: state => state.chat.hostName
    }),
    validationElements() {
      return [this.$refs.chatName, this.$refs.dataPrivacyCheckbox]
    }
  },
  watch: {
    hostName: {
      handler() {
        if (this.hostName && this.loggedIn) {
          this.startChat()
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.loggedIn) {
      this.name = this.$store.getters['chat/userName']
    }
  },
  beforeUnmount() {
    this.$store.commit('chat/setName', this.name)
  },
  methods: {
    focusPrimaryInputField() {
      if (!this.loggedIn) {
        this.$nextTick(() => this.$refs.chatName.focus())
      }
    },
    async startChat() {
      if (this.loggedIn || (await this.validate(true, true))) {
        if (!this.name && this.loggedIn) {
          await this.$store.dispatch('customerData/requestCustomerData')
          this.name = `${this.personal.firstname} ${this.personal.lastname}`
        }

        this.$store.commit('chat/setName', this.name)
        this.$store.dispatch('chat/initiateChatConnection')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 20px;
}

.welcome-screen__input {
  width: 80%;
}

.welcome-screen__text {
  text-align: center;
}
</style>
