<template>
  <Validator
    ref="validator"
    :value="text"
    :rules="rules"
    class="textarea__validator"
    :style="wrapperStyleObject"
    @valid="onValid"
  >
    <div class="textarea__wrapper" @mousedown.stop="onMousedown">
      <h3 v-if="title" class="subsubtitle textarea__title">{{ title }}</h3>
      <div
        :class="{
          textarea__disabled: disabled,
          textarea__focused: focused,
          textfield__clicked: mouseUsed
        }"
        class="input__slot"
      >
        <label
          v-if="label"
          class="textarea__label"
          :for="textareaId"
          :class="{ magnify: magnified }"
        >
          {{ label }}
        </label>
        <textarea
          :id="textareaId"
          ref="textarea"
          v-model="text"
          class="input__element"
          :class="{ 'input__element--dynamic': dynamic }"
          :placeholder="placeholder"
          :tabindex="tabindex"
          :style="styleObject"
          :disabled="disabled"
          @focus="onFocus($event)"
          @blur="onBlur($event)"
          @input="onInput($event)"
        ></textarea>
        <div v-if="showIcon" class="textarea__icon">
          <slot name="append">
            <Icon
              v-if="showCheckmark"
              class="icon"
              name="checkmark"
              height="24"
              color="#17b95c"
            />
          </slot>
        </div>
      </div>
    </div>
  </Validator>
</template>

<script>
import { uniqueId } from 'src/lib/helpers'
import Icon from '../icon/icon.vue'
import Validator from './validator.vue'

export default {
  name: 'TextArea',
  components: {
    Validator,
    Icon
  },
  props: {
    label: { type: String, default: undefined },
    title: { type: String, default: undefined },
    placeholder: { type: String, default: undefined },
    tabindex: { type: Number, default: undefined },
    height: { type: Number, default: 100 },
    rules: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    disableCheckmark: { type: Boolean, default: false },
    modelValue: { type: String, default: undefined },
    dynamic: { type: Boolean, default: false }
  },
  emits: ['focus', 'blur', 'update:modelValue'],
  data() {
    return {
      valid: undefined,
      text: this.modelValue,
      focused: false,
      mouseUsed: false
    }
  },
  computed: {
    textareaId() {
      return `textarea-${uniqueId()}`
    },
    magnified() {
      return !this.text && !this.focused && !this.placeholder
    },
    styleObject() {
      if (this.dynamic) {
        return { maxHeight: '90px' }
      }

      return {
        height: `${this.height}px`
      }
    },
    wrapperStyleObject() {
      if (this.dynamic) {
        return {}
      }

      return {
        minHeight: `${this.height + 80}px`
      }
    },
    showCheckmark() {
      if (this.disableCheckmark || this.disabled) {
        return false
      }

      return this.valid
    },
    showIcon() {
      return this.showCheckmark || this.$slots.append
    }
  },
  watch: {
    modelValue(val) {
      this.text = val
      if (this.dynamic) {
        this.$refs.textarea.style.height = 'auto'
        if (val !== '') {
          this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight}px`
        }
      }
    }
  },
  methods: {
    reset() {
      this.$emit('update:modelValue', undefined)
      this.resetValidator()
    },
    resetValidator() {
      this.$refs.validator.reset()
    },
    validate(showError) {
      return this.$refs.validator.validate(showError)
    },
    focus() {
      this.$refs.textarea.focus()
    },
    onValid(valid) {
      this.valid = valid
    },
    onFocus(event) {
      if (!this.focused) {
        this.resetValidator()
        this.focused = true
        this.$emit('focus', event)
      }
    },
    onBlur(event) {
      this.focused = false
      this.mouseUsed = false
      this.validate(true)
      this.$emit('blur', event)
    },
    onInput(event) {
      const value = event.target.value

      this.$emit('update:modelValue', value)
    },
    onMousedown(event) {
      this.mouseUsed = true
      if (event.target !== this.$refs.textarea) {
        event.preventDefault()
      }

      this.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  textarea {
    resize: none;
  }
}

.textarea__label {
  color: rgb(var(--root-color-rgb) / 70%);
  line-height: 1.1;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
}

.input__element {
  line-height: 1.25;
  border: 0;
  outline: none;
  padding-bottom: 8px;
  padding-top: 2px;
  margin-top: 27px;

  &:focus {
    box-shadow: none;
  }
}

.input__element--dynamic {
  margin-top: 0;
}

.input__slot {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1.5px solid $input-border-color;
  min-height: 56px;
  padding-right: 12px;
  padding-top: 6px;
  padding-left: 15px;
  background-color: var(--background-primary);
  cursor: text;
  border-radius: 5px;

  &.textarea__focused {
    border: 1.5px solid $input--active-border-color;
    caret-color: $input--active-border-color;
    box-shadow: 0 0 0 2px var(--background-primary), 0 0 0 4px var(--root-color);
    transition: box-shadow 0.2s;
    transform-origin: center;

    > .textarea__label {
      color: $input--active-border-color;
    }
  }

  &:hover {
    border: 1.5px solid $input--active-border-color;
  }

  &.textarea__disabled {
    border: 0;
    cursor: default;
    background-color: var(--background-tertiary);
    color: rgb(var(--root-color-rgb) / 45%);

    > .textarea__label {
      color: rgb(var(--root-color-rgb) / 45%);
    }

    > .input__element {
      cursor: default;
    }

    &:hover {
      border: 0;
    }
  }

  &.textfield__clicked {
    box-shadow: none;
  }
}

@include is-dark {
  .input__slot {
    color: var(--root-color);
  }
}

.show-validation-error .input__slot {
  border: 1.5px solid var(--error-color);

  > .textarea__label {
    color: var(--error-color);
  }

  &:hover {
    border: 1.5px solid var(--error-color);
  }
}

.magnify {
  transform-origin: 0 0;
  font-size: 16px;
  transform: translateY(12px);
}

.textarea__icon {
  position: absolute;
  top: 12px;
  right: 8px;
}

.textarea__validator {
  flex-grow: 1;
}
</style>
