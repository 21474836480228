<template>
  <Validator
    ref="validator"
    :value="rating"
    :rules="[validationRule]"
    class="textfield__validator"
    :message-id="messageId"
    @valid="onValid"
  >
    <fieldset
      class="feedback__stars"
      :aria-describedby="!valid ? messageId : undefined"
    >
      <input
        id="1-star"
        ref="first-input"
        v-model="rating"
        type="radio"
        name="rating"
        value="1"
        class="visually-hidden"
      />
      <label for="1-star" :class="{ something: rating > 0 }" class="star">
        <span class="visually-hidden">1 Stern</span>
        <Icon name="star" :width="size" :height="size" class="star__icon" />
      </label>
      <input
        id="2-stars"
        v-model="rating"
        type="radio"
        name="rating"
        value="2"
        class="visually-hidden"
      />
      <label for="2-stars" class="star" :class="{ something: rating > 1 }">
        <span class="visually-hidden">2 Sterne</span
        ><Icon name="star" :width="size" :height="size" />
      </label>
      <input
        id="3-stars"
        v-model="rating"
        type="radio"
        name="rating"
        value="3"
        class="visually-hidden"
      />
      <label for="3-stars" class="star" :class="{ something: rating > 2 }"
        ><span class="visually-hidden">3 Sterne</span
        ><Icon name="star" :width="size" :height="size"
      /></label>
      <input
        id="4-stars"
        v-model="rating"
        type="radio"
        name="rating"
        value="4"
        class="visually-hidden"
      />
      <label
        data-cy="4-stars"
        for="4-stars"
        class="star"
        :class="{ something: rating > 3 }"
        ><span class="visually-hidden">4 Sterne</span
        ><Icon name="star" :width="size" :height="size"
      /></label>
      <input
        id="5-stars"
        v-model="rating"
        type="radio"
        name="rating"
        value="5"
        class="visually-hidden"
      />

      <label
        data-cy="5-stars"
        for="5-stars"
        class="star"
        :class="{ something: rating > 4 }"
        ><span class="visually-hidden">5 Sterne</span
        ><Icon name="star" :width="size" :height="size"
      /></label>
    </fieldset>
  </Validator>
</template>

<script>
import { uniqueId } from 'src/lib/helpers'
import Icon from '../../icon/icon.vue'
import Validator from 'src/components/common/input/validator.vue'

export default {
  name: 'FeedbackStars',
  components: {
    Validator,
    Icon
  },
  inject: ['$http'],
  props: {
    size: {
      type: String,
      default: '2rem'
    }
  },
  data() {
    return {
      rating: null,
      messageId: `mess-${uniqueId()}`,
      validationRule: value => {
        return value !== 0 || 'Geben Sie eine Bewertung ab'
      }
    }
  },
  watch: {
    rating() {
      this.$store.commit('chat/setRating', this.rating)
    }
  },
  mounted() {
    this.rating = this.$store.getters['chat/getRating']
  },
  methods: {
    validate(showError) {
      return this.$refs.validator?.validate(showError)
    },
    focus() {
      return this.$refs['first-input'].focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback__stars {
  display: flex;
  justify-content: center;
  border: none;
  padding: 0;

  label {
    color: var(--root-color);
    cursor: pointer;
    border: 2px solid transparent;
  }
}

.something {
  color: #f0e200 !important;
}

.feedback__stars input:checked + label {
  color: #f0e200;
  border-bottom-color: #f0e200;
}

.feedback__stars input:focus + label {
  outline: solid transparent;
  box-shadow: 0 0 0 2px var(--background-primary), 0 0 0 4px var(--root-color);
  border-radius: 2px;
  transition: box-shadow 0.2s;
  transform-origin: center;
  appearance: none;
}

.feedback__stars:hover input + label {
  color: #f0e200;
}

.feedback__stars input:hover ~ label {
  color: var(--root-color);
}

.feedback__stars input:hover + label {
  color: #f0e200;
}

.feedback__stars input:focus:not(:focus-visible) + label {
  outline: none;
  box-shadow: none;
}
</style>
