<template>
  <div data-cy="feedback" class="feedback">
    <h1 class="feedback__header">Wie zufrieden waren Sie mit unserem Chat?</h1>
    <FeedbackStars
      ref="stars"
      :size="$breakpoint.fromTablet() ? '3rem' : '2rem'"
      class="feedback__rating-stars"
    />

    <TextArea
      ref="primaryInputField"
      v-model="comment"
      data-cy="feedback-message"
      class="feedback__message"
      label="Feedback"
      placeholder="Was können wir verbessern?"
      disable-checkmark
    >
    </TextArea>
    <ButtonComponent
      v-if="!ratingSent"
      :clickhandler="rateChat"
      class="feedback__button"
      data-cy="feedback-button"
      >Bewertung abgeben</ButtonComponent
    >

    <span v-else data-cy="feedback-greetings" class="feedback__appreciation"
      >Vielen Dank für Ihr Feedback!</span
    >
    <a
      :href="generateDownloadLink()"
      :download="getFileName()"
      target="_blank"
      class="history__download"
      data-cy="history-download"
      >Chatverlauf herunterladen</a
    >
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import FeedbackStars from './feedback_stars.vue'
import TextArea from 'src/components/common/input/textarea.vue'
import validate from 'src/mixins/validate'

export default {
  name: 'ChatFeedback',
  components: {
    TextArea,
    FeedbackStars,
    ButtonComponent
  },
  mixins: [validate],

  data() {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapState({
      ratingSent: state => state.chat.feedbackSent,
      hasEmailHistory: state => state.chat.hasEmailHistory,
      messages: state => state.chat.messages
    }),
    validationElements() {
      return [this.$refs.stars]
    }
  },
  mounted() {
    this.comment = this.$store.getters['chat/tmpComment']
  },
  beforeUnmount() {
    this.$store.commit('chat/setTmpComment', this.comment)
  },
  methods: {
    async rateChat() {
      if (await this.validate(true, true)) {
        this.$store.dispatch('chat/submitRating', this.comment)
      }
    },
    focusPrimaryInputField() {
      this.$nextTick(() => this.$refs.primaryInputField.focus())
    },
    getFileName() {
      return `${
        this.$brand.name
      }_chat_verlauf_${new Date().toLocaleDateString()}.html`
    },
    generateDownloadLink() {
      let fileContent = `<h1>Chatverlauf ${
        this.$brand.name
      } - ${new Date().toLocaleString()}</h1>`
      let filteredMessages = this.messages.filter(
        message => message.ack || message.type === 'incoming'
      )

      filteredMessages.forEach(message => {
        fileContent =
          fileContent +
          `<section style="padding-top: 10px"><p style="margin: 0">${message.timestamp} ${message.sender}:</p><p style="margin: 0">${message.data}</p></section>`
      })
      let file = new Blob([fileContent], { type: 'text/html' })

      return window.URL.createObjectURL(file)
    }
  }
}
</script>

<style lang="scss" scoped>
.feedback {
  flex-grow: 1;
  margin: 20px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.feedback__header {
  text-align: center;
  font-size: calc-rem(22px);
  margin: 0 20px;

  @include breakpoint($from-tablet) {
    font-size: calc-rem(24px);
  }
}

.feedback__rating {
  border: none;
  align-self: center;
}

.feedback__rating-stars {
  margin: 0;
  padding: 0;

  :deep(.validator__msg) {
    text-align: center;
  }
}

.feedback__message {
  flex-direction: column;
  margin: 0;
  display: flex;
  justify-content: center;
}

.feedback__appreciation {
  text-align: center;
}

.history__download {
  text-align: center;
}
</style>
