<template>
  <div class="chat">
    <div id="chatWindow" class="chat__messages">
      <div
        v-for="(message, index) in messages"
        :key="index"
        class="message"
        :class="{
          'message--outgoing': message.type === 'outgoing',
          'message--incoming': message.type === 'incoming'
        }"
        :data-cy="`message-bubble-${index}`"
      >
        <div class="message__container">
          <div
            :id="'message-' + message.reqId"
            class="message__bubble message__animation"
            :class="{
              message__tail: isLastMessageOfType(message.type, index),
              'message--pending': !message.ack && message.type === 'outgoing'
            }"
            :role="[message.type === 'incoming' ? 'alert' : undefined]"
          >
            <p
              data-cy="message-content"
              class="message__content"
              v-html="safeHTML(message.data)"
            ></p>
            <a
              v-if="message.url"
              :download="message.fileName"
              :href="message.url"
              target="_blank"
              class="message__download"
            >
              {{ message.fileName }} ({{ message.size }})</a
            >
            <p class="message__sender">
              {{ message.sender }} {{ message.timestamp }}
            </p>
          </div>
          <div class="message__error">
            <p
              v-if="message.error"
              class="message__error-text"
              role="alert"
              :aria-describedby="'message-' + message.reqId"
            >
              <Icon
                name="info"
                :color="'#ff6580'"
                :up="'3'"
                :height="'18'"
                :width="'18'"
              />
              Beim Versenden ist ein Fehler aufgetreten.
            </p>
          </div>
        </div>
        <button
          v-if="message.error"
          class="message__error-button"
          :aria-describedby="'message-' + message.reqId"
          @click="resendMessage(message)"
        >
          <Icon
            name="repeat"
            :color="'var(--root-color)'"
            :height="'24'"
            :width="'24'"
          />
        </button>
      </div>
      <div v-if="closedByAgent" class="message__animation message--chat-ended">
        <p>Der Chat wurde beendet</p>
        <FeedbackStars />
        <ButtonComponent
          class="message--chat-ended-button"
          :clickhandler="endChat"
          >Feedback absenden</ButtonComponent
        >
      </div>
      <div
        v-if="closedDueToError && !closedByAgent"
        class="message__animation message--chat-ended"
      >
        <p class="paragraph">
          Die Verbindung wurde unterbrochen. Bitte beenden Sie den Chat und
          starten Sie ihn wahlweise neu.
        </p>
        <ButtonComponent
          class="message--chat-ended-button"
          :clickhandler="closeChat"
          >Chat schließen</ButtonComponent
        >
      </div>
    </div>
    <p
      v-if="!closedByAgent && !closedDueToError"
      class="chat__notification"
      :class="{ 'chat__notification--active': notification }"
    >
      {{ notification }}
    </p>
    <hr />
    <div class="chat__input-area">
      <div class="send-message-inputfield">
        <TextArea
          ref="primaryInputField"
          v-model="newMessage"
          placeholder="Geben Sie hier eine Chatnachricht ein"
          data-cy="chat-input-field"
          :height="25"
          disable-checkmark
          dynamic
          class="chat__input-area__textfield"
          @keydown.enter.shift.exact.prevent="sendMessage()"
        />
        <button
          class="send-message-button"
          data-cy="chat-send-button"
          aria-label="Nachricht senden"
          @click="sendMessage()"
        >
          <Icon name="send" alt-text="Nachricht senden" height="24" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import FeedbackStars from './feedback_stars.vue'
import Icon from '../../icon/icon.vue'
import TextArea from 'src/components/common/input/textarea.vue'
import VueSecureHTML from 'vue-html-secure'

export default {
  name: 'ChatComponent',
  components: {
    TextArea,
    FeedbackStars,
    ButtonComponent,
    Icon
  },
  inject: ['$http'],
  data() {
    return {
      newMessage: '',
      isTyping: false,
      timeoutId: undefined
    }
  },
  computed: {
    ...mapState({
      messages: state => state.chat.messages,
      minimized: state => state.chat.minimized,
      userName: state => state.chat.userName,
      notification: state => state.chat.notification,
      closedByAgent: state => state.chat.closedByAgent,
      closedDueToError: state => state.chat.closedDueToError
    })
  },
  watch: {
    messages: {
      handler() {
        this.scrollMessages()
      },
      deep: true,
      flush: 'post'
    },
    minimized: {
      handler(val) {
        if (!val) {
          this.$nextTick(this.scrollMessages)
        }
      },
      immediate: true
    },
    closedByAgent(val) {
      if (val) {
        this.$nextTick(this.scrollMessages)
      }
    },
    closedDueToError(val) {
      if (val) {
        this.$nextTick(this.scrollMessages)
      }
    },
    newMessage: {
      handler() {
        if (this.isTyping === false) {
          this.$store.dispatch('chat/userStartedTyping')
          this.isTyping = true
        }

        clearTimeout(this.timeoutId)

        this.timeoutId = setTimeout(() => {
          if (this.isTyping === true) {
            this.$store.dispatch('chat/userStoppedTyping')
            this.isTyping = false
          }
        }, 1000)
      }
    }
  },
  methods: {
    focusPrimaryInputField() {
      this.$nextTick(() => this.$refs.primaryInputField.focus())
    },
    scrollMessages() {
      let chatWindow = document.getElementById('chatWindow')

      chatWindow.scrollTop = chatWindow.scrollHeight
    },
    sendMessage() {
      if (this.newMessage === '') {
        return
      }

      this.$store.dispatch('chat/sendMessage', this.safeHTML(this.newMessage))
      this.newMessage = ''
      this.$nextTick(() => this.$refs.primaryInputField.focus())
    },
    resendMessage(message) {
      this.$store.dispatch('chat/sendMessage', message.data)
      this.$store.commit('chat/removeMessage', message.reqId)
    },
    endChat() {
      this.name = ''
      this.newMessage = ''
      this.$store.commit('chat/setName', null)
      this.$store.commit('chat/setUiState', 'feedback')
      this.$store.dispatch('chat/stopChatConnection')
    },
    closeChat() {
      this.$bus.emit('close-chat')
    },
    isLastMessageOfType(type, index) {
      return this.messages.findLastIndex(elem => elem.type === type) === index
    },
    getMessageAriaLabel(message) {
      if (message.type === 'outgoing') {
        return `Nachricht: ${message.data} von ${message.sender} um ${
          new Date(message.timestamp).toLocaleTimeString
        } Uhr`
      } else {
        return undefined
      }
    },
    safeHTML(message) {
      return VueSecureHTML.safeHTML(message)
    }
  }
}
</script>

<style lang="scss" scoped>
.chat {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chat__messages {
  height: 200px;
  flex: 1 1 auto;
  overflow: scroll;
  scroll-behavior: smooth;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.chat__input-area {
  flex: 0 0 auto;
  margin: 20px;
  background-color: var(--background-primary);
}

.chat__input-area__textfield {
  max-height: 100px;
}

.message__sender {
  font-size: calc-rem(14px);
  margin-bottom: 1px;
  text-align: end;
}

.message {
  width: 100%;
  display: flex;
}

.message__container {
  width: 70%;
}

.message__bubble {
  margin-bottom: 5px;
  padding: 5px 15px;
  overflow: initial;
  position: relative;
}

.message--incoming {
  justify-self: left;

  .message__bubble {
    background-color: var(--background-tertiary);
    border-radius: 15px 15px 15px 0;
  }

  .message__tail {
    border-bottom-left-radius: 0;
  }
}

.message--incoming .message__tail::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-left: 12px solid var(--background-tertiary);
  border-right: 12px solid transparent;
  border-top: 8px solid var(--background-tertiary);
  border-bottom: 8px solid transparent;
  left: 0;
  bottom: -16px;
}

.message__content {
  white-space: pre-line;
  margin-bottom: 8px;
}

.message--outgoing {
  align-self: end;
  justify-content: end;
  color: $root-color;

  :deep(a) {
    color: #000;
  }

  .message__bubble {
    background-color: $c_special-blue;
    border-radius: 15px 15px 5px;
  }

  .message__content {
    text-align: left;
  }

  .message__tail {
    border-bottom-right-radius: 0;
  }
}

.message--outgoing .message__tail::before {
  content: '';
  width: 0;
  height: 0;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid $c_special-blue;
  border-top: 8px solid $c_special-blue;
  border-bottom: 8px solid transparent;
  right: 0;
  bottom: -16px;
}

.message--pending.message__tail::before {
  border-right: 12px solid rgba($c_special-blue, 0.5);
  border-top: 8px solid rgba($c_special-blue, 0.5);
}

.message--pending.message__bubble {
  background-color: rgba($c_special-blue, 0.5);
}

.message__animation {
  animation-duration: 0.3s;
  animation-name: fade-in;
  animation-delay: 0.2s;
  animation-fill-mode: backwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.message__download {
  display: block;
  text-align: center;
  margin-bottom: 8px;
}

.message__error {
  position: relative;
  display: flex;
  justify-content: center;
}

.message__error-button {
  cursor: pointer;
  height: 50%;
  margin: 10px 5px 0 15px;
  border-radius: 3px;
  fill: var(--root-color);
}

.message__error-text {
  font-size: calc-rem(14px);
  color: var(--error-color);
  margin: 0 0 10px;
}

.message--chat-ended {
  color: var(--root-color);
  text-align: center;
  margin: auto -20px -20px;
  background-color: var(--alert-notice-background);
  padding: 10px;
  border-top: 15px solid var(--background-primary);
  flex-direction: column;
  gap: 10px;

  p {
    color: var(--root-color);

    &:not(.paragraph) {
      margin: 0;
    }
  }

  &-button {
    margin: auto;
  }

  :deep(.feedback__stars > label) {
    color: var(--root-color);
  }
}

.chat__notification {
  font-size: calc-rem(14px);
  margin: 0 0 0 20px;
  font-style: italic;
  height: 1.5rem;
}

.chat__notification--active::after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  animation: ellipsis steps(4, end) 1200ms infinite;
  content: '\2026';
  width: 0;
}

@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

.send-message-inputfield {
  width: 100%;
  position: relative;
  display: flex;
  align-items: end;
  gap: 5px;
}

.send-message-button {
  height: 57px;
  width: 60px;
  border-radius: 5px;
  margin-bottom: 1px;
  background-color: var(--button_primary);
  color: var(--button_primary_label);

  &:hover {
    background-color: var(--button_primary_hover);
  }
}

.feedback__rating {
  border: none;
  align-self: center;
}

.feedback__rating-stars {
  display: flex;
  flex-direction: row-reverse;
  font-size: 2.5em;
  justify-content: space-around;
  padding: 0 0.2em;
  text-align: center;
  width: 6em;
  margin: 0 auto 10px;
}

.feedback__rating-stars input {
  display: none;
}

.feedback__rating-stars label {
  color: #ccc;
  cursor: pointer;
}

.feedback__rating-stars :checked ~ label {
  color: #f90;
}

.feedback__rating-stars label:hover,
.feedback__rating-stars label:hover ~ label {
  color: #fc0;
}
</style>
