<template>
  <div class="summary">
    <h2 class="summary__header">
      Lassen Sie sich den Chatverlauf ganz bequem per E-Mail zusenden
    </h2>
    <Textfield
      v-if="!historyRequested"
      ref="email"
      v-model="mail"
      class="summary__email-input"
      label="Geben Sie ihre E-Mail hier ein"
      type="email"
      name="email"
      autocomplete="email"
      :rules="[ruleEmail]"
    />

    <div class="summary__send-history">
      <ButtonComponent v-if="!historyRequested" :clickhandler="sendChatHistory"
        >Chatverlauf zusenden</ButtonComponent
      ><span v-else>Wir haben Ihnen den Verlauf an {{ mail }} zugesendet.</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { ruleEmail } from 'src/lib/rules'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Textfield from 'src/components/common/input/textfield.vue'
import validate from 'src/mixins/validate'

export default {
  name: 'ChatEndScreen',
  components: {
    Textfield,
    ButtonComponent
  },
  mixins: [validate],
  data() {
    return {
      mail: '',
      ruleEmail: ruleEmail
    }
  },
  computed: {
    ...mapState({
      historyRequested: state => state.chat.historyRequested
    }),
    validationElements() {
      return [this.$refs.email]
    }
  },
  mounted() {
    this.mail = this.$store.getters['chat/email']
  },
  beforeUnmount() {
    this.$store.commit('chat/setEmail', this.mail)
  },
  methods: {
    async sendChatHistory() {
      if (await this.validate(true, true)) {
        this.$store.dispatch('chat/sendChatHistoryAsMail', this.mail)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary {
  flex-grow: 1;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
  align-items: center;
  gap: 25px;
  flex-direction: column;
}

.summary__header {
  font-size: calc-rem(18px);
  text-align: center;
}

.summary__email-input {
  width: 90%;
}

.summary__send-history {
  align-self: end;
}
</style>
