<template>
  <div class="header">
    <button
      v-if="leftCallback"
      class="header__button"
      :aria-label="leftText"
      data-cy="header-left-button"
      @click="leftCallback"
      @mouseover="openMenu('left')"
      @mouseout="openMenu('')"
      @focus="openMenu('left')"
      @blur="openMenu('')"
    >
      <div>
        <Icon
          :name="leftIcon"
          :color="'var(--root-color)'"
          :height="'24'"
          :width="'24'"
        />
      </div>
    </button>
    <div v-else style="width: 34px"></div>
    <Transition name="slide-left">
      <div
        v-if="menuOpen === 'left'"
        class="header__button-menu header__button-menu--left"
        aria-hidden="true"
        @click="leftCallback"
        @mouseover="openMenu('left')"
        @mouseout="openMenu('')"
      >
        {{ leftText }}
      </div>
    </Transition>

    <h1 ref="header" class="header__title">
      {{ title }}
    </h1>
    <button
      v-if="rightCallback"
      class="header__button"
      :aria-label="rightText"
      data-cy="header-right-button"
      @click="rightCallback"
      @mouseover="openMenu('right')"
      @mouseout="openMenu('')"
      @focus="openMenu('right')"
      @blur="openMenu('')"
    >
      <Icon
        :name="rightIcon"
        :color="'var(--root-color)'"
        :height="'24'"
        :width="'24'"
      />
    </button>
    <div v-else style="width: 34px"></div>
    <Transition name="slide-right">
      <div
        v-if="menuOpen === 'right'"
        class="header__button-menu header__button-menu--right"
        aria-hidden="true"
        @click="rightCallback"
        @mouseover="openMenu('right')"
        @mouseout="openMenu('')"
      >
        {{ rightText }}
      </div>
    </Transition>
  </div>
</template>

<script>
import Icon from '../../icon/icon.vue'

export default {
  name: 'ChatHeader',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: ''
    },
    rightText: {
      type: String,
      default: ''
    },
    rightCallback: {
      type: Function,
      default: undefined
    },
    rightIcon: {
      type: String,
      default: undefined
    },
    leftText: {
      type: String,
      default: ''
    },
    leftCallback: {
      type: Function,
      default: undefined
    },
    leftIcon: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      menuOpen: '',
      menuTimeouts: {}
    }
  },
  methods: {
    openMenu(menu) {
      if (menu === '') {
        this.menuTimeouts.outer = setTimeout(() => {
          this.menuOpen = ''
          this.menuTimeouts.inner = setTimeout(() => {
            if (this.$refs.header) {
              this.$refs.header.style = 'visibility: inherit'
            }
          }, 300)
        }, 400)

        return
      }

      clearTimeout(this.menuTimeouts.outer)
      clearTimeout(this.menuTimeouts.inner)
      if (menu === this.menuOpen) {
        return
      }

      this.menuOpen = menu
      this.$refs.header.style = 'visibility: hidden'
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 10px;
  background-color: var(--background-primary);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.header__title {
  margin: 0;
  text-align: center;
  font-size: calc-rem(22px);
}

.header__button {
  cursor: pointer;
  width: 34px;
  z-index: 2;
  background-color: var(--background-primary);
  position: relative;
}

.header__button-menu {
  z-index: 1;
  position: absolute;
  background-color: var(--background-primary);
  padding: 5px;
}

.header__button-menu--left {
  left: 50px;
}

.header__button-menu--right {
  right: 50px;
}

.slide-left-enter-active {
  transition: all 0.3s ease-out;
}

.slide-left-leave-active {
  transition: all 0.3s ease-in;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-35px);
  opacity: 0;
}

.slide-right-enter-active {
  transition: all 0.3s ease-out;
}

.slide-right-leave-active {
  transition: all 0.3s ease-in;
}

.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(35px);
  opacity: 0;
}
</style>
