<template>
  <div>
    <div
      v-if="isOpen && !minimized"
      ref="modal"
      data-cy="chat-modal"
      class="modal"
      :class="{ 'modal--offset': $route.name === 'Checkout' }"
      @keydown="handleKeyEvent"
    >
      <ChatHeader
        v-if="!appmode"
        :title="$brand.name + ' Chat'"
        :left-text="'Chat beenden'"
        :left-callback="endChat"
        :left-icon="'exit'"
        :right-text="'Chat minimieren'"
        :right-callback="toggleChatWindow"
        :right-icon="'window-minimize'"
      />
      <hr />
      <WelcomeScreen
        v-if="state === 'welcome'"
        ref="chatstep"
        class="chat-element"
      />
      <Loading
        v-if="state === 'loading'"
        class="chat-element loading-spinner"
        text="Verbindung wird hergestellt."
      />
      <Chat
        v-if="state === 'chat'"
        ref="chatstep"
        class="chat-element"
        @minimize="close"
      />
      <Feedback
        v-if="state === 'feedback'"
        ref="chatstep"
        class="chat-element"
      />
      <History v-if="state === 'history'" ref="chatstep" class="chat-element" />
    </div>
    <div
      v-if="minimized || isOpen"
      class="minimized"
      :class="{ 'minimized--offset': $route.name === 'Checkout' }"
    >
      <div
        v-if="minimized"
        class="notification"
        :class="{ 'notification--show': backgroundNotifications > 0 }"
      >
        {{ formattedNotifications() }}
      </div>
      <button
        data-cy="toggle-button"
        class="minimized__button"
        @click="toggleChatWindow"
      >
        <Icon v-if="minimized" name="chat" height="24" color="#304f63" />
        <Icon v-else name="close-simple" height="24" color="#304f63" />
      </button>
    </div>
  </div>
</template>

<script>
import { disableScrollY, enableScrollY } from 'src/lib/helpers'
import { mapState } from 'vuex'
import Chat from './components/chat.vue'
import ChatHeader from './components/header.vue'
import Feedback from './components/feedback.vue'
import History from './components/history.vue'
import Icon from '../icon/icon.vue'
import Loading from '../spinner/loading.vue'
import WelcomeScreen from './components/welcome_screen.vue'

export default {
  name: 'ChatModal',
  components: {
    ChatHeader,
    WelcomeScreen,
    Chat,
    Feedback,
    History,
    Loading,
    Icon
  },
  data() {
    return {
      announcementInterval: undefined,
      maxAnnouncements: 5,
      announcementsCounter: 5,
      yKeyCode: 89,
      eventListenerController: new AbortController()
    }
  },
  computed: {
    ...mapState({
      chatAvailable: state => state.chat.chatAvailable,
      minimized: state => state.chat.minimized,
      isOpen: state => state.chat.chatWindowOpen,
      state: state => state.chat.uiState,
      userName: state => state.chat.userName,
      closedByAgent: state => state.chat.closedByAgent,
      backgroundNotifications: state => state.chat.backgroundNotifications,
      appmode: state => state.authentication.appmode
    }),
    isMac() {
      return window.navigator.platform.match('Mac')
    }
  },
  watch: {
    backgroundNotifications(val) {
      if (val > 0) {
        const notificationSound = new Audio(
          require('public/assets/audio/chat-notification.mp3')
        )

        notificationSound.play()
        if (
          this.announcementInterval &&
          this.announcementsCounter !== this.maxAnnouncements
        ) {
          this.resetAnnouncmentData()
        }

        if (!this.announcementInterval) {
          //Reannounce every 60 seconds
          this.announcementInterval = setInterval(
            () => this.announceBackgroundNotification(val),
            60000
          )
        }
      } else {
        this.resetAnnouncmentData()
      }
    },
    state() {
      this.$nextTick(() => {
        if (
          this.$refs.chatstep &&
          this.$refs.chatstep.focusPrimaryInputField &&
          !this.appmode
        ) {
          this.$refs.chatstep.focusPrimaryInputField()
        }
      })
    }
  },
  created() {
    this.$bus.on('open-chat', this.open)
    this.$bus.on('close-chat', this.close)

    if (this.appmode) {
      window.addEventListener('beforeunload', () => {
        const chatIsStillActive =
          this.state === 'chat' && this.state.closedByAgent !== true

        if (chatIsStillActive) {
          this.$store.dispatch('chat/stopChatConnectionOnWindowClose')
          this.$store.dispatch('chat/endChat')
        }
      })
    }
  },
  mounted() {
    this.$store.dispatch('chat/restartWebsocket')
    document.addEventListener('keydown', this.toggleOnKeyboardShortcut)
    if (this.$breakpoint.upToTablet() && this.isOpen) {
      disableScrollY()
      this.handleMobileKeyboard()
    }
  },
  beforeUnmount() {
    enableScrollY()
    document.removeEventListener('keydown', this.toggleOnKeyboardShortcut)
    this.$bus.off('open-chat', this.open)
    this.eventListenerController.abort()
  },
  methods: {
    async open() {
      if (this.isOpen) {
        return
      }

      if (this.minimized) {
        this.toggleChatWindow()

        return
      }

      this.$store.commit('chat/setUiState', 'welcome')
      this.toggleChatWindow()
      this.$nextTick(() => {
        if (this.$refs.chatstep && this.$refs.chatstep.focusPrimaryInputField) {
          this.$refs.chatstep.focusPrimaryInputField()
        }
      })
    },
    close() {
      this.toggleChatWindow()
      this.$store.dispatch('chat/endChat')
    },
    toggleChatWindow() {
      if (this.isOpen) {
        this.$store.commit('chat/closeChatWindow')
        this.$store.commit('chat/setMinimizedState', true)
        enableScrollY()
        this.eventListenerController.abort()
      } else {
        this.handleMobileKeyboard()
        this.$store.commit('chat/openChatWindow')
        this.$store.commit('chat/setMinimizedState', false)
        this.$store.commit('chat/clearBackgroundNotifications')
        if (this.$breakpoint.upToTablet()) {
          disableScrollY()
        }
      }
    },
    endChat() {
      this.$store.commit('chat/setName', null)
      if (this.state === 'chat') {
        this.$store.commit('chat/setUiState', 'feedback')
        this.$store.dispatch('chat/stopChatConnection')
      } else {
        this.$store.dispatch('chat/endChat')
        enableScrollY()
      }
    },
    formattedNotifications() {
      return this.backgroundNotifications <= 9
        ? this.backgroundNotifications
        : '9+'
    },
    announceBackgroundNotification(count) {
      if (this.announcementsCounter > 0) {
        this.announcementsCounter -= 1
        setTimeout(() => {
          this.$announcer.set(
            `Sie haben eine neue Nachricht im Chat! Anzahl ungelesener Nachrichten: ${count}! Um den Chat zu maximieren tippen Sie ${
              this.isMac ? 'Command' : 'Steuerung'
            } + Umschalttaste + "Y" auf Ihrer Tastatur.`,
            'assertive'
          )
        }, 300)
        this.$announcer.set('', 'off')
      } else {
        this.resetAnnouncmentData()
      }
    },
    resetAnnouncmentData() {
      clearInterval(this.announcementInterval)
      this.announcementsCounter = this.maxAnnouncements
      this.announcementInterval = undefined
    },
    toggleOnKeyboardShortcut(event) {
      //  CTRL | CMD
      const ctr = this.isMac ? event.metaKey : event.ctrlKey
      const shift = event.shiftKey
      const activationKey =
        event.keyCode === this.yKeyCode || event.which === this.yKeyCode

      if (ctr && shift && activationKey) {
        this.toggleChatWindow()
        event.preventDefault()
      }
    },
    handleKeyEvent(event) {
      if (event.key === 'Tab' && this.$breakpoint.upToTablet()) {
        const focusableList = this.$refs.modal.querySelectorAll(
          'button, a, input:not([tabindex="-1"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        )

        // do nothing if there is no element to focus
        if (focusableList.length < 1) {
          event.preventDefault()

          return
        }

        const last = focusableList.length - 1

        // focus first on tab last element
        if (event.shiftKey === false && event.target === focusableList[last]) {
          event.preventDefault()
          focusableList[0].focus()

          return
        }

        // focus last on shift+tab first element
        if (event.shiftKey === true && event.target === focusableList[0]) {
          event.preventDefault()
          focusableList[last].focus()

          return
        }
      }
    },
    handleMobileKeyboard() {
      // Resize chat on mobile phones
      // Opening the keyboard should not move the content up
      // outside of the visible area.
      if ('virtualKeyboard' in navigator) {
        // When the virtual keyboard API is supported, CSS env variables
        // are used to set a bottom margin.
        navigator.virtualKeyboard.overlaysContent = true
      } else {
        // Fallback for iOS and browsers that do not support virtual keyboard API
        let pendingUpdate = false
        let viewportHandler = () => {
          if (pendingUpdate) return
          // Only apply this fallback on mobile layouts and reset changes that
          // were made by resizing the browser window from a mobile layout to larger
          if (this.$breakpoint.fromTablet()) {
            this.$refs.modal.style.top = ''
            this.$refs.modal.style.bottom = ''

            return
          }

          pendingUpdate = true

          requestAnimationFrame(() => {
            pendingUpdate = false

            this.$refs.modal.style.top = `${Math.max(
              0,
              window.visualViewport.offsetTop
            )}px`

            if (window.visualViewport.offsetTop >= 0) {
              this.$refs.modal.style.bottom = `${Math.max(
                0,
                window.innerHeight -
                  window.visualViewport.height -
                  window.visualViewport.offsetTop
              )}px`
            }

            // scroll messages after resizing so newest messages are on screen
            this.$refs.chatstep?.scrollMessages()
          })
        }

        this.eventListenerController = new AbortController()
        window.visualViewport.addEventListener('scroll', viewportHandler, {
          signal: this.eventListenerController.signal
        })
        window.visualViewport.addEventListener('resize', viewportHandler, {
          signal: this.eventListenerController.signal
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: var(--background-primary);
  display: flex;
  flex-direction: column;
}

@include breakpoint($from-tablet) {
  .modal {
    top: unset;
    left: unset;
    right: 15px;
    bottom: 75px;
    width: 100%;
    height: calc(100% - 75px);
    max-height: 650px;
    margin: 12px auto 0;
    border: 1px solid var(--root-color);
    border-radius: 8px;
    box-shadow: var(--root-color) 0 0 5px;
    max-width: 400px;
    opacity: 1;
  }

  .modal--offset {
    bottom: 175px;
  }
}

@include is-dark {
  .modal {
    border: 2px solid var(--root-color);
  }
}

.chat-element {
  flex-grow: 1;
  overflow: auto;
  margin-bottom: env(keyboard-inset-height, 0);
}

.minimized {
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.minimized--offset {
  bottom: 125px;
}

.minimized__button {
  background-color: var(--button_highlight);
  color: var(--button_highlight_label);
  border-radius: 5px;
  padding: 10px 15px;

  &:hover {
    background-color: var(--button_highlight_hover);
  }
}

.notification {
  visibility: hidden;
  position: relative;
  transform: translateX(-40%) translateY(60%);
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: red;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.notification--show {
  visibility: inherit;
}

.loading-spinner {
  text-align: center;
}
</style>
